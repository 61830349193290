import Chart from "chart.js/auto"

class SingleHexagonalRadarChart extends HTMLElement {
  connectedCallback() {
    if (!window.Chart) {
      window.Chart = Chart
    }
    this.dispatchEvent(new CustomEvent("chart-loaded", { bubbles: true }))
  }
}

customElements.define("single-hexagonal-radar-chart", SingleHexagonalRadarChart)

export default SingleHexagonalRadarChart
