class SalaryTable extends HTMLElement {
  connectedCallback() {
    const accordionItems = this.querySelectorAll("[data-accordion]")
    if (accordionItems.length === 0) return

    const links = this.querySelectorAll(".corporation-link")
    links.forEach((link) => {
      link.addEventListener("click", (event) => event.stopPropagation())
    })

    const toggleAccordion = (item) => {
      const targetClass = item.dataset.accordion
      const contents = this.querySelectorAll(`.${targetClass}`)
      const iconUp = document.getElementById(`${targetClass}-icon-up`)
      const iconDown = document.getElementById(`${targetClass}-icon-down`)

      const isHidden = contents[0].classList.contains("om2-u-dpn")

      iconUp?.classList.toggle("om2-u-dpn", !isHidden)
      iconDown?.classList.toggle("om2-u-dpn", isHidden)

      contents.forEach((content) =>
        content.classList.toggle("om2-u-dpn", !isHidden)
      )

      if (
        !isHidden &&
        (item.getBoundingClientRect().top < 0 ||
          window.innerHeight < item.getBoundingClientRect().top)
      ) {
        item.scrollIntoView({ behavior: "smooth" })
      }
    }

    accordionItems.forEach((item) => {
      item.addEventListener("click", () => toggleAccordion(item))
    })
  }
}
customElements.define("salary-table", SalaryTable)

export default SalaryTable
