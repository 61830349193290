import jQuery from "jquery"
window.$ = window.jQuery = jQuery

import Rails from "@rails/ujs"
Rails.start()

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import select2 from "select2"
select2()

import "@/javascripts/_fontawesome"

import "@/javascripts/_element"
import "@/javascripts/_utility"
import "@/javascripts/components"
import "chartkick"

import.meta.glob("../images/**/*", { eager: true })
